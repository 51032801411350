<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách IMEI'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="excelModal">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="checkPermission('ACTIVE_IMEI')" class="ml-3">
            <router-link to="/imeis/active-imei">
              <b-button v-bind:style="btnCreate" variant="warning" size="sm">
                <i style="font-size: 1rem" class="fa fa-check"></i>Kích hoạt
              </b-button>
            </router-link>
          </div>
          <div v-if="checkPermission('STOCK_UPDATE')" class="ml-3">
            <b-dropdown size="sm" id="dropdown-right" right variant="primary">
              <template slot="button-content">
                <i style="font-size: 1rem" class="fa fa-recycle"></i>
                <span class="font-weight-bolder">Đổi sản phẩm</span>
              </template>
              <b-dropdown-item>
                <router-link to="/imeis/change-product-imei">
                  <span style="color: #3f4254; width: 100%">
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                    &nbsp; Nhập thông tin</span
                  >
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/imeis/import-change-product-imei">
                  <span style="color: #3f4254">
                    <i style="font-size: 1rem" class="la la-file-excel-o"></i>
                    &nbsp; Nhập từ file excel
                  </span>
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <div class="col-md-3">
            <b-input
              size="sm"
              placeholder="Nhập mã IMEI"
              v-model="search"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </div>
          <div class="col-md-3">
            <b-input
              size="sm"
              placeholder="Nhập mã, tên sản phẩm"
              v-model="searchProduct"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </div>
          <div class="col-md-3 pl-2 pr-0">
            <vue-autosuggest
              v-model="selectedCateName"
              :suggestions="filteredCateOptions"
              @selected="onSelectedCate"
              :limit="200"
              @input="onInputCateChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Chọn danh mục',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.suggestionName }}
              </div></vue-autosuggest
            >
          </div>
          <div class="col-md-3 pl-2 pr-0">
            <vue-autosuggest
              v-model="selectedCate2Name"
              :suggestions="filteredCate2Options"
              @selected="onSelectedInternalCate"
              :limit="200"
              @input="onInputInternalCateChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Chọn danh mục nội bộ',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.suggestionName }}
              </div></vue-autosuggest
            >
          </div>
        </b-row>
        <b-row class="mb-5" style="margin-left: 0px">
          <div class="col-md-3 pl-0 pr-0">
            <vue-autosuggest
              style="padding-left: 0px; padding-right: 20px"
              v-model="searchProvider"
              :suggestions="filteredOptionProvider"
              @selected="onSelectedProvider"
              :limit="10"
              @input="onInputChangeProvider"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Chọn NCC',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.name }}
              </div></vue-autosuggest
            >
          </div>
          <div class="col-md-3 pl-0 pr-0">
            <vue-autosuggest
              style="padding-left: 2px; padding-right: 20px"
              v-model="searchStock"
              :suggestions="filteredOptions"
              @selected="onSelected"
              :limit="10"
              @input="onInputChangeStore"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Chọn cửa hàng',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.name }}
              </div></vue-autosuggest
            >
          </div>
          <div class="col-md-3 pl-0 pr-2">
            <div class="search-col">
              <b-input-group>
                <date-picker
                  placeholder="Từ"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="searchFromDay"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Đến"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="searchToDay"
                ></date-picker>
              </b-input-group>
            </div>
          </div>
          <div class="col-md-3">
            <multiselect
              v-model="valueStatus"
              :options="optionsStatus"
              :multiple="true"
              track-by="code"
              :close-on-select="false"
              @select="onSelectStatus($event)"
              @remove="onRemoveStatus($event)"
              :show-labels="false"
              :showNoResults="true"
              :showPointer="false"
              :custom-label="customLabelStatus"
              placeholder="Chọn trạng thái"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ valueStatus.length }} trạng thái đã chọn</span
                ></template
              >
              <span
                class="checkbox-label"
                slot="option"
                slot-scope="scope"
                @click.self="select(scope.option)"
              >
                {{ scope.option.name }}
                <input
                  class="test"
                  type="checkbox"
                  v-model="scope.option.checked"
                  @focus.prevent
                />
              </span>
              <span
                class="checkbox-label"
                style="font-size: 12px"
                slot="noResult"
              >
                Không có kết quả
              </span>
            </multiselect>
            <!-- </div> -->
          </div>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ convertPrice(totalItem) }}
              </p>
            </div>
          </b-col>
        </b-row>

        <b-table
          :items="listImeis"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="checkAllPro"
                @change="checkAll"
                size="lg"
              ></b-form-checkbox>
            </span>
          </template>
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.selected"
                @change="clickIndex"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(productName)="row">
            <span
              v-if="row.item.barCode"
              v-text="row.item.barCode"
              style="white-space: normal; font-size: 12px; font-weight: 500"
            ></span>
            <p
              v-if="row.item.productCode"
              style="font-size: 12px; font-weight: 500; margin-bottom: 0"
            >
              ( {{ row.item.productCode }} )
            </p>
            <span
              v-text="row.item.productName"
              style="white-space: normal"
            ></span>
          </template>
          <template v-slot:cell(provider)="row">
            <p
              v-if="row.item.provider"
              style="font-size: 13px; font-weight: 4500; margin-bottom: 0"
            >
              {{ row.item.provider }}
            </p>
            <span
              v-text="row.item.importDate"
              style="white-space: normal; color: blue"
            ></span>
          </template>
          <template v-slot:cell(imeiCode)="row">
            <div class="row">
              <div
                class="imeiCode col-md-10"
                @click="linkToImeiHistory(row.item)"
                style="cursor: pointer"
              >
                <span v-text="row.item.imeiCode"></span>
              </div>
              <i
                class="fas fa-copy ml-4"
                style="cursor: pointer; font-size: 11px"
                v-clipboard:copy="row.item.imeiCode"
                title="Copy to clipboard"
                @click="makeToastSuccess('Copied to clipboard!')"
              ></i>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <span
              v-text="checkStatus(row.item.status)"
              class="label font-weight-bold label-lg label-inline"
              v-bind:class="{
                'label-light-warning': row.item.status === 1,
                'label-light-success': row.item.status === 2,
                'label-light-danger': row.item.status === 3,
                'label-light-default': row.item.status === 4,
              }"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(originalPrice)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.originalPrice)"></span>
            </div>
          </template>
          <template v-slot:cell(sellingPrice)="row">
            <div
              @mouseover="hoverPrice = true"
              @mouseleave="hoverPrice = false"
              style="text-align: end; cursor: pointer"
            >
              <span @click="handleShowPriceModel(row.item)">
                <i v-if="hoverPrice" class="fas fa-edit"></i>
              </span>

              <span v-text="convertPrice(row.item.sellingPrice)"></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon-eye"></i>
                    &nbsp; Chi tiết sản phẩm
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showActiveModal(row.item.imeiCode)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="fa fa-check"></i>
                    &nbsp; Kích hoạt
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="productName"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Giá nhập</label
                >
                <b-form-input
                  v-model="originalPrice"
                  size="sm"
                  placeholder="Nhập giá nhập"
                  v-mask="mask"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputSellingPrice"
                  >Giá bán</label
                >
                <b-form-input
                  v-model="sellingPrice"
                  size="sm"
                  placeholder="Nhập giá bán"
                  v-mask="mask"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateProduct"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowPriceModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số IMEI:
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <div class="d-flex justify-content-end">
            <b-col>
              <b-pagination-nav
                class="customPagination"
                v-if="numberOfPage >= 2"
                :link-gen="linkGen"
                :number-of-pages="numberOfPage"
                use-router
                @change="fetchData"
                align="right"
                first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
                prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
                next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
                last-class="page-item-last btn btn-icon btn-sm my-1 "
                page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
              >
                <template v-slot:first-text>
                  <span>
                    <i class="ki ki-double-arrow-back icon-xs"></i>
                  </span>
                </template>

                <template v-slot:prev-text>
                  <i class="ki ki-arrow-back icon-xs"></i>
                </template>

                <template v-slot:next-text>
                  <i class="ki ki-arrow-next icon-xs"></i>
                </template>

                <template v-slot:last-text>
                  <span class="text-info">
                    <i class="ki ki-double-arrow-next icon-xs"></i>
                  </span>
                </template>
              </b-pagination-nav>
            </b-col>
          </div>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal
      ref="excel-modal"
      hide-footer
      title="Xuất excel danh sách imei"
      id="excel-modal"
    >
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  v-model="selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
    <ActiveImei ref="active-imei-modal" />
  </div>
</template>

<style>
.labelInput {
  font-weight: 600;
}
.productCode:hover {
  text-decoration: underline;
}

.inputText {
  width: 22%;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link {
  font-weight: 500;
  font-size: 1rem;
}

.customPagination /deep/ .page-item .page-link:hover i {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.selectedProClass {
  width: 4%;
  text-align: center;
}
.productNameClass {
  width: 20%;
}
.productNameNotPriceClass {
  width: 20%;
}
.imeiClass {
  width: 15%;
}
.providerClass {
  width: 15%;
}
.storeClass {
  width: 10%;
  align-items: center;
}
.importPriceProClass {
  width: 10%;
}
.salePriceProClass {
  width: 10%;
}
.statusProClass {
  width: 10%;
  text-align: center;
}
.dropdown-header {
  color: #3699ff !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
a {
  width: 100%;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.imeiCode:hover {
  text-decoration: underline;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px !important;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 12px !important;
  min-height: fit-content !important;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 12px !important;
}

.multiselect__input,
.multiselect__single {
  min-height: 10px;
}
.search-col {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 10px;
}
</style>

<style scoped>
#autosuggest {
  padding-left: 10px;
  padding-right: 15px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import axios from 'axios';
import decounce from 'debounce';
import { BASE_URL, TIME_TRIGGER } from '../../../utils/constants';
import { xoa_dau, removeAccents } from './../../../utils/common';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import fileDownload from '../../../utils/file-download';
import ActiveImei from '../../components/imeis/ActiveImei';

export default {
  data() {
    return {
      originalPrice: 0,
      sellingPrice: 0,
      productName: '',
      productId: '',
      hoverPrice: false,
      selectedProvider: null,
      listProvider: [],
      btnCreate: {
        fontWeight: '600!important',
      },
      selected: [],
      checkAllPro: false,
      fields: [
        {
          key: 'selected',
          label: '',
          thStyle: { textAlign: 'center' },
          tdClass: 'selectedProClass',
          thClass: 'selectedProClass',
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'productNameClass',
          thClass: 'productNameClass',
        },
        {
          key: 'imeiCode',
          label: 'IMEI',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'imeiClass',
          thClass: 'imeiClass',
        },
        {
          key: 'provider',
          label: 'Nhà cung cấp',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'providerClass',
          thClass: 'providerClass',
        },
        {
          key: 'store',
          label: 'Kho',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'storeClass',
          thClass: 'storeClass',
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'importPriceProClass',
          thClass: 'importPriceProClass',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'salePriceProClass',
          thClass: 'salePriceProClass',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'statusProClass',
          thClass: 'statusProClass',
        },
        { key: 'actions', label: '' },
      ],
      search: '',
      listImeis: [],
      listCategories: [],
      selectedCategory: null,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      listStatus: [
        { id: '', name: 'Tất cả trạng thái' },
        { id: 1, name: 'Mới' },
        { id: 2, name: 'Đã bán' },
        { id: 3, name: 'Đang vận chuyển' },
        { id: 4, name: 'Lỗi' },
        { id: 5, name: 'Đã trả NCC' },
        { id: 6, name: 'Đang chuyển kho' },
        { id: 7, name: 'Đã trả bảo hành' },
        { id: 8, name: 'Đang bảo hành' },
        { id: 9, name: 'Đang giữ hàng' },
      ],
      onLoading: false,
      numberOfPage: 0,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      showEditPriceModal: false,
      mask: currencyMask,
      listStore: [],
      selectedStore: null,
      searchStock: '',
      selectedRowExcel: 'all',
      selectedListExcel: 'all',
      showHeaderExcel: false,
      indeterminate: false,
      filteredOptionProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      fromNo: 0,
      toNo: 0,
      LIMIT_PAGE: 30,
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      cate2Options: [
        {
          data: [],
        },
      ],
      filteredCate2Options: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      searchFromDay: '',
      searchToDay: '',
      valueStatus: [],
      optionsStatus: [
        {
          code: 1,
          name: 'Mới',
          checked: false,
        },
        {
          code: 2,
          name: 'Đã bán',
          checked: false,
        },
        {
          code: 3,
          name: 'Đang vận chuyển',
          checked: false,
        },
        {
          code: 4,
          name: 'Lỗi',
          checked: false,
        },
        {
          code: 5,
          name: 'Đã trả NCC',
          checked: false,
        },
        {
          code: 6,
          name: 'Đang chuyển kho',
          checked: false,
        },
        {
          code: 7,
          name: 'Đã trả bảo hành',
          checked: false,
        },
        {
          code: 8,
          name: 'Đang bảo hành',
          checked: false,
        },
        {
          code: 9,
          name: 'Đang giữ hàng',
          checked: false,
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Multiselect,
    datePicker,
    ActiveImei,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'IMEI', route: '/imeis' },
      { title: 'Danh sách IMEI' },
    ]);
  },
  created() {
    let imeiCode = this.$route.query.code;
    let searchProduct = this.$route.query.searchProduct;
    let storeId = this.$route.query.storeId;
    if (imeiCode !== undefined) {
      this.search = imeiCode;
    }
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    if (storeId !== undefined) {
      this.selectedStore = storeId;
    }
    this.fetchData();
    this.fetchProvider();
    this.checkViewOriginalPrice();
    this.fetchStore();
    this.fetchCategory();
    this.fetchInternalCategory();
  },
  methods: {
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    checkStatus: function (item) {
      switch (item) {
        case 1:
          return 'Mới';
        case 2:
          return 'Đã bán';
        case 3:
          return 'Đang vận chuyển';
        case 4:
          return 'Lỗi';
        case 5:
          return 'Đã trả NCC';
        case 6:
          return 'Đang chuyển kho';
        case 7:
          return 'Đã trả bảo hành';
        case 8:
          return 'Đang bảo hành';
        case 9:
          return 'Đang giữ hàng';
        default:
          return '';
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('PRODUCT_VIEW')) {
        count++;
      }
      if (localData.checkPermission('PRODUCT_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: function (item) {
      alert(item);
    },
    fetchData: function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (this.searchStock.trim() === '') {
        this.selectedStore = null;
      }
      let listStatus = [];
      this.optionsStatus.forEach((element) => {
        if (element.checked) {
          listStatus.push(element.code);
        }
      });
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      const param = {
        page: this.page,
        limit: this.LIMIT_PAGE,
        status: listStatus,
        search: this.search ? this.search.trim() : '',
        providerId: this.selectedProvider,
        searchProduct: this.searchProduct,
        storeId: this.selectedStore,
        fromDate: this.searchFromDay
          ? moment(this.searchFromDay, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : null,
        toDate: this.searchToDay
          ? moment(this.searchToDay, 'DD/MM/YYYY').format('yyyy-MM-DD 23:59:59')
          : null,
        cateId: this.selectedCateId,
        internalCateId: this.selectedCate2Id,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('imeis', paramQuery).then(({ data }) => {
        this.listImeis = [];
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;
        this.fromNo = (this.page - 1) * this.LIMIT_PAGE + 1;
        this.toNo = this.LIMIT_PAGE * this.page;
        if (this.toNo > this.totalItem) {
          this.toNo = this.totalItem;
        }
        data.data.list_imei.forEach((element) => {
          let item = {
            id: element.id,
            barCode: element.barCode,
            productCode: element.productCode,
            productName: element.productName,
            imeiCode: element.imeiCode,
            provider: element.providerName,
            store: element.storeName,
            originalPrice: element.originalPrice,
            sellingPrice: element.sellingPrice,
            status: element.status,
            selected: false,
            importDate: element.importDate,
          };
          this.listImeis.push(item);
        });
        this.onLoading = false;
      });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-imei',
        query: { id: item.id },
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProvider = data.data.providers;
        let noneProvider = {
          id: null,
          name: 'Tất cả',
        };
        this.listProvider.unshift(noneProvider);
        this.listProvider.map((element) => {
          this.optionsProvider[0].data.push(element);
        });
        this.filteredOptionProvider = [{ data: this.optionsProvider[0].data }];
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
      this.onFilter();
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    handleShowPriceModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.productId = item.id;
      this.productName = item.productName;
      this.originalPrice = item.originalPrice;
      this.sellingPrice = item.sellingPrice;
    },
    updateProduct: async function () {
      const data = {
        id: this.productId,
        originalPrice: unMaskPrice(this.originalPrice),
        sellingPrice: unMaskPrice(this.sellingPrice),
      };
      await ApiService.put('imeis/update-price', data).then(
        async (response) => {
          if (response.status === 1) {
            this.fetchData();
            this.showEditPriceModal = !this.showEditPriceModal;
            this.makeToastSuccess(response.message);
          } else {
            this.fetchData();
            this.showEditPriceModal = !this.showEditPriceModal;
            this.makeToastFaile(response.message);
          }
        }
      );
    },
    linkToImeiHistory: function (item) {
      const { href } = this.$router.resolve({
        name: 'list-imei-history',
        query: { code: item.imeiCode },
      });
      window.open(href, '_blank');
    },
    excelModal: async function () {
      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      this.processBar = 0;
      // get list status filter
      const listStatus = this.optionsStatus
        .filter((element) => element.checked)
        .map((element) => element.code);

      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }

      const options = {
        url: `${BASE_URL}productEmeiStock/export-excel`,
        method: 'GET',
        responseType: 'blob',
        params: {
          status: listStatus,
          search: this.search,
          providerId: this.selectedProvider,
          searchProduct: this.searchProduct,
          storeId: this.selectedStore,
          fromDate: this.searchFromDay
            ? moment(this.searchFromDay, 'DD/MM/YYYY').format(
                'yyyy-MM-DD 00:00:00'
              )
            : null,
          toDate: this.searchToDay
            ? moment(this.searchToDay, 'DD/MM/YYYY').format(
                'yyyy-MM-DD 23:59:59'
              )
            : null,
          cateId: this.selectedCateId,
          internalCateId: this.selectedCate2Id,
        },
      };

      await axios(options).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    debounceInput: decounce(function () {
      this.onFilter();
    }, TIME_TRIGGER),
    checkViewOriginalPrice() {
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        let listNew = this.fields.filter(
          (item) => item.key !== 'originalPrice'
        );
        this.fields = [];
        listNew.forEach((element) => {
          this.fields.push(element);
        });
        for (let index = 0; index < this.fields.length; index++) {
          const element = this.fields[index];
          if (element.key === 'productName') {
            this.fields[index].tdClass = 'productNameNotPriceClass';
            this.fields[index].thClass = 'productNameNotPriceClass';
            break;
          }
        }
      }
    },
    fetchStore: async function () {
      this.optionsStore[0].data = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
        });
        this.filteredOptions = [{ data: this.optionsStore[0].data }];
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-imeis',
      });
      this.fetchData();
    },
    showHeader() {
      if (this.selectedRowExcel === 'row') {
        this.showHeaderExcel = true;
      } else {
        this.showHeaderExcel = false;
      }
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.selectedProvider = option.item.id;
    },
    onInputChangeProvider(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;

      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          let itemName = xoa_dau(item.name);
          return itemName.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionProvider = [
        {
          data: filteredData,
        },
      ];
    },
    fetchCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [{ data: this.cateOptions[0].data }];
      });
    },
    fetchInternalCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.cate2Options[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.cate2Options[0].data.push(item);
          });
          this.filteredCate2Options = [{ data: this.cate2Options[0].data }];
        }
      );
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedInternalCate(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputInternalCateChange(text) {
      this.selectedCate2Name = text;

      const filteredData = this.cate2Options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCate2Options = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectStatus(option) {
      const index = this.optionsStatus.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStatus[index].checked = true;
    },
    onRemoveStatus(option) {
      const index = this.optionsStatus.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStatus[index].checked = false;
    },
    customLabelStatus(option) {
      return `${option.name}`;
    },
    showActiveModal(imeiCode) {
      this.$refs['active-imei-modal'].showModal(imeiCode);
    },
    checkAll() {
      this.listImeis.forEach((element) => {
        element.selected = this.checkAllPro;
      });
    },
    clickIndex() {
      if (this.checkAllPro == true) {
        this.checkAllPro = false;
      } else {
        var count = 0;
        this.listImeis.forEach((element) => {
          if (element.selected == true) {
            count += 1;
            if (count == 10) {
              this.checkAllPro = true;
            }
          }
        });
      }
    },
  },
};
</script>
